<!--suppress JSUnusedLocalSymbols -->
<template>
  <div>
    <b-overlay
      :show="false"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
    >
      <b-alert
        v-if="raidActive"
        variant="info"
        show
      >
        <div class="alert-body">
          <span><strong>Raid Active!</strong> Messages are sent to raided Channel</span>
        </div>
      </b-alert>
      <b-card
        title="Start/Stop"
      >
        <b-card-text class="mb-0">
          <!--        <span>Start/stop </span>-->
          <!--        <code>switch</code>-->
          <!--        <span> to </span>-->
          <!--        <code>true</code>-->
        </b-card-text>

        <!-- switch -->

        <div>
          <div class="demo-inline-spacing">
            <b-col class="col-md-5">
              <b-form-checkbox
                v-model="activeAutopilot"
                name="check-button"
                switch
                :disabled="true"
                inline
              >
                Activate Autopilot
                <feather-icon
                  v-b-tooltip.hover.top="'Automatically send remaining Followers during streams depending on remaining duration of your License'"
                  icon="AlertCircleIcon"
                  class="mr-25"
                />
              </b-form-checkbox>

            </b-col>
            <b-col>
              <!--              <b-form-checkbox-->
              <!--                v-model="viewlist"-->
              <!--                name="check-button"-->
              <!--                switch-->
              <!--                inline-->
              <!--              >-->
              <!--                Viewlist-->
              <!--              </b-form-checkbox>-->

            </b-col>
          </div>
        </div>
      </b-card>
      <!--      <CreditHistory />-->
      <div>
        <b-overlay
          :show="active"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <!--          <b-card-->
          <!--            title="Instant Follow + Message"-->
          <!--          >-->
          <!--            <b-card-text class="mb-0" />-->

          <!--            &lt;!&ndash; switch &ndash;&gt;-->
          <!--            <b-row>-->
          <!--              <b-col md="12">-->
          <!--                <b-form-group-->
          <!--                  label="Message"-->
          <!--                >-->
          <!--                  <b-row>-->
          <!--                    <b-col>-->
          <!--                      <b-form-input-->
          <!--                        id="instantMessage"-->
          <!--                        v-model="instantMessage"-->
          <!--                        type="text"-->
          <!--                        placeholder="Message"-->
          <!--                        @keyup.enter="sendInstantFollow"-->
          <!--                      />-->
          <!--                    </b-col>-->
          <!--                    <b-col>-->
          <!--                      <b-button-->
          <!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--                        :disabled="true"-->
          <!--                        :variant="themeColor"-->
          <!--                        @click="sendInstantFollow"-->
          <!--                      >-->
          <!--                        <feather-icon-->
          <!--                          icon="PlayIcon"-->
          <!--                          class="mr-25"-->
          <!--                        />-->
          <!--                        <span>Send Follow + Message {{ raidedText }}</span>-->
          <!--                      </b-button>-->
          <!--                    </b-col>-->
          <!--                  </b-row>-->

          <!--                </b-form-group>-->
          <!--              </b-col>-->
          <!--              <b-col />-->
          <!--            </b-row>-->
          <!--          </b-card>-->
          <b-row class="match-height">
            <b-col class="col-md-6 col-12 ">
              <b-card
                title="Send Followers via dripfeed"
              >
                <b-row>
                  <b-col>
                    <b-form-group>
                      Number of Followers
                      <feather-icon
                        v-b-tooltip.hover.top="'The Amount of Followers'"
                        icon="AlertCircleIcon"
                        class="mr-25"
                        style="margin-top: -2px"
                      />
                      <div class="mt-3">
                        <vue-slider
                          v-model="followCounter"
                          :min="0"
                          :max="remainingFollowers"
                          :tooltip="'always'"
                          :direction="'ltr'"
                          :drag-on-click="true"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      Dripfeed-Time in Minutes
                      <feather-icon
                        v-b-tooltip.hover.top="'The selected Followers are sent over this Time (in Minutes).'"
                        icon="AlertCircleIcon"
                        class="mr-25"
                        style="margin-top: -2px"
                      />
                      <div class="mt-3">
                        <vue-slider
                          v-model="dripFeedTime"
                          label="25"
                          value="25"
                          :min="minDripFeedTime"
                          :max="1440"
                          :tooltip="'always'"
                          :direction="'ltr'"
                          :drag-on-click="true"
                          :tooltip-formatter="Tformatter"
                        />
                        <!--                        <template v-slot:value>-->
                        <!--                          test-->
                        <!--                        </template>-->
                      </div>
                    </b-form-group>
                    Total(hh/mm/ss):
                    {{ calcTime }}
                  </b-col>
                </b-row>
                <b-row class="align-items-center">
                  <b-col class="col-12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="disableStart || disableAllButtons"
                      block
                      variant="success"
                      class="mb-1 mt-1"
                      @click="sendDripFeedFollower()"
                    >
                      <feather-icon
                        icon="PlayIcon"
                        class="mr-25"
                      />
                      <span>Send Followers</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col class="col-md-6">
              <FollowerGoalOverview :data="data.goalOverview" />
            </b-col>
          </b-row>
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="CaretRightFill"
                font-scale="3"
                animation="pulse"
              />
              <p id="startstop">
                Bot is running
              </p>
            </div>
          </template>
        </b-overlay>
      </div>

      <template #overlay>
        <div class="text-center">
          <!--          <b-icon-->
          <!--            icon="cash"-->
          <!--            font-scale="3"-->
          <!--            animation="pulse"-->
          <!--          />-->
          <p id="cancel-label">
            Followbot will be available soon!
          </p>
        </div></template>
    </b-overlay>
    <last-follows />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components,no-unused-vars */
import {
  BFormCheckbox, BIconCaretRightFill, BAlert, BImg, BOverlay, BNavItemDropdown, BCardText, BCard, BFormInput, BButton, BFormGroup, BCol, BRow, BIcon, BIconCash, BFormValidFeedback, BDropdown, BDropdownItem, BDropdownDivider, BFormInvalidFeedback, VBTooltip,
} from 'bootstrap-vue'
import FollowerGoalOverview from '@/views/dashboard/FollowerGoalOverview.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VueSlider from 'vue-slider-component'
import firebase from 'firebase'
import LastFollows from '@/views/pages/follower/lastFollows.vue'
// import CreditHistory from '@/views/pages/Panel/CreditHistory.vue'

export default {
  components: {
    LastFollows,
    // CreditHistory,
    FollowerGoalOverview,
    BAlert,
    BCol,
    BOverlay,
    BRow,
    BImg,
    BNavItemDropdown,
    BFormCheckbox,
    BCard,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    VueSlider,
    BFormInput,
    BButton,
    VBTooltip,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIconCaretRightFill,
    BIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
      instantMessage: '',
      disableStart: false,
      followCounter: 0,
      remainingFollowers: 0,
      activeAutopilot: false,
      raidActive: false,
      viewCapacity: { max: 10, current: 0 },
      usage: { series: [100, 0] },
      timeslider: [30, 180],
      selectedCountry: 'Germany',
      loop: true,
      minutes: 0,
      seconds: 15,
      max: 15,
      valueslider: 1,
      viewlistslider: 5,
      code: null,
      show: false,
      sentFollowers: 5,
      twitch: '',
      active: false,
      viewlist: false,
      chatbot: true,
      viewbot: true,
      showstart: false,
      showstop: false,
      dripFeedTime: 60,
      disableAllButtons: false,
      minDripFeedTime: 0,
    }
  },
  computed: {
    themeColor() {
      if (this.raidActive) return 'info'
      return 'success'
    },
    raidedText() {
      if (this.raidActive) return 'to raided Channel'
      return ''
    },
    calcTime() {
      const h = Math.floor(this.dripFeedTime / 60).toString().padStart(2, '0')
      // eslint-disable-next-line no-mixed-operators
      const m = Math.floor(this.dripFeedTime % 60).toString().padStart(2, '0')
      return `${h}:${m}:00`
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    remainingFollowers() {
      // console.log(this.remainingFollowers)
      if (this.remainingFollowers === 0) this.disableAllButtons = true
    },
    dripFeedTime() {
      if (this.dripFeedTime < this.followCounter * 2) {
        this.dripFeedTime = this.followCounter * 2
        this.minDripFeedTime = this.followCounter * 2
      }
    },
    followCounter() {
      if (this.followCounter === 0) {
        this.disableStart = true
      } else this.disableStart = false
      if (this.dripFeedTime < this.followCounter * 2) {
        this.dripFeedTime = this.followCounter * 2
        this.minDripFeedTime = this.followCounter * 2
      }
      this.minDripFeedTime = this.followCounter * 2
      if (this.followCounter < 0) this.followCounter = 0
      // if (this.followCounter > 15 && this.minDripFeedTime < this.followCounter - 15) {
      //   this.minDripFeedTime = this.followCounter - 15
      //   if (this.dripFeedTime < this.minDripFeedTime) {
      //     this.dripFeedTime = this.minDripFeedTime
      //   }
      // } else {
      //   this.minDripFeedTime = 0
      // }
    },
    timeslider() {
      const x = this.timeslider / 60
      const comma = (x * 10) % 10
      this.minutes = x - comma
      this.seconds = this.timeslider % 60
    },
    valueslider() {
      // CreditHistory.methods.updateViewerCalc()
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
      if (this.valueslider < 1) {
        this.valueslider = 1
      }
    },
    viewlistslider() {
      if (this.viewlistslider > this.valueslider) {
        this.viewlistslider = this.valueslider
      }
      if (this.viewlistslider < 0) {
        this.viewlistslider = 0
        this.disableStart = true
      }
    },
  },
  created() {
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  mounted() {
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .onSnapshot(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.disableAllButtons = true
          this.show = true
        } else {
          // eslint-disable-next-line no-lonely-if
          if (snap.data().follower.sent === snap.data().follower.sent123) {
            let remainingFollows
            if (snap.data().License.TotalDays > 25) {
              remainingFollows = snap.data().follower[1] / 3
            } else {
              remainingFollows = snap.data().follower[1] / 10
            }
            sx.collection('User')
              .doc(userData.id)
              .set({
                follower: {
                  remaining: remainingFollows, sent: 0, realRemaining: remainingFollows, realSent: 0, sendMessage: true, dripFeedAmount: 10, dripFeedTime: 25, currentMessage: '',
                },
              }, { merge: true })
          } else {
            this.raidedChannel = snap.data().raid.twitchname
            this.raidActive = snap.data().raid.active
            this.follow = snap.data().raid.followActive
            this.followCounter = Math.round((snap.data().follower.remaining / 2))
            this.unfollowAgain = snap.data().raid.unfollowAgain
            this.remainingFollowers = snap.data().follower.remaining
            this.sentFollowers = snap.data().follower.sent
            this.viewBotActivated = snap.data().raid.viewBotActivated
            this.viewListActivated = snap.data().raid.viewListActivated
            this.raidDuration = snap.data().raid.raidDuration
            this.viewListCount = snap.data().raid.viewListCount
            this.viewListCount ??= 5
            this.viewerCount ??= 5
            this.follow ??= false
            this.followCounter ??= 5
            this.unfollowAgain ??= false
            this.remainingFollowers ??= 5
            this.viewBotActivated ??= true
            this.viewListActivated ??= true
            this.raidDuration ??= 5600
          }
          if (this.remainingFollowers === 0) this.disableAllButtons = true
          // console.log(snap.data().Botpanel.chatinterval)
          // if (this.max > 15) { // TODO REMOVE
          //   this.max = 15
          // }
        }
      })
  },

  methods: {
    Tformatter(val) {
      const h = Math.floor(val / 60)
      const min = val % 60 > 9 ? val % 60 : `0${val % 60}`
      return `${h}:${min}`
    },
    sendInstantFollow() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .get()
        .then(() => {
          sx.collection('System')
            .doc('NGROK')
            .get()
            .then(snap2 => {
              sx.collection('User')
                .doc(userData.id)
                .set({
                  follower: {
                    currentMessage: this.instantMessage,
                  },
                }, { merge: true })
              // fetch(`http://localhost:5000/sendInstantMessage/${userData.id}`)
              fetch(`https://${snap2.data().Link}.ngrok.io/sendOneFollow/${userData.id}`)
                .then(response => response.json())
              // eslint-disable-next-line no-unused-vars
                .then(data => {
                  this.remainingFollowers -= 1
                  this.sentFollowers += 1
                  sx.collection('User')
                    .doc(userData.id)
                    .set({
                      follower: {
                        remaining: this.remainingFollowers,
                        sent: this.sentFollowers,
                      },
                    }, { merge: true })
                    .then(() => {
                      this.$swal({
                        title: 'Sending Follow + Message!',
                        text: 'Sending message',
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.$swal({
                        title: 'Error!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$swal({
                    title: 'Error!',
                    text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            })
        })
    },
    async sendDripFeedFollower() {
      console.log('Start')
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      const { twitchname } = (await sx.collection('User').doc(userData.id).get()).data()
      const followCollection = sx.collection('followList')
        .doc('toFollow')
        .collection('followTasks')
      const followDocId = (await followCollection.add({
        dripfeedInterval: Math.round((this.dripFeedTime) / this.followCounter),
        finished: false,
        followed: 0,
        toFollow: this.followCounter,
        totalFollow: this.followCounter,
        startDate: new Date(Date.now()),
        geminosId: userData.id,
        twitchname,
      }))
      console.log(followDocId.id)
      await sx.collection('User').doc(userData.id).collection('Follower').doc(followDocId.id)
        .set({
          dripfeedInterval: Math.round((this.dripFeedTime) / this.followCounter),
          finished: false,
          followed: 0,
          toFollow: this.followCounter,
          totalFollow: this.followCounter,
          startDate: new Date(Date.now()),
          geminosId: userData.id,
          twitchname,
        }, { merge: true })
        .then(() => {
          this.remainingFollowers -= this.followCounter
          this.sentFollowers += this.followCounter
          sx.collection('User')
            .doc(userData.id)
            .set({
              follower: {
                dripFeedTime: this.dripFeedTime * 60,
                dripFeedAmount: this.followCounter,
                remaining: this.remainingFollowers,
                sent: this.sentFollowers,
              },
            }, { merge: true })
            .then(() => {
              this.$swal({
                title: 'Starting Followers!',
                text: 'Starting Followers!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              // console.log(error)
              this.$swal({
                title: 'Error!',
                text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        })
      // eslint-disable-next-line no-unused-vars
      // console.log(data)

        .catch(error => {
          // console.log(error)
          this.$swal({
            title: 'Error!',
            text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    changepattern(country) {
      this.selectedCountry = country
    },
    switchlooptrue() {
      this.loop = true
    },
    switchloopfalse() {
      this.loop = false
    },
    // fetch(`https://${snap2.data().Link}.ngrok.io/start/${userData.id}/${this.chatbot}/${snap.data().twitchname}/${this.valueslider}/${this.timeslider[0]}/${this.timeslider[1]}`)
    onShown() {
      // Focus the cancel button when the overlay is showing
    },
    onHidden() {
      // Focus the show button when the overlay is removed
    },
    // top start
    checklicense() {
      if (this.twitch.length > 0) {
        // console.log(`user id: ${firebase.auth().currentUser.uid}`)
        const userData = getUserData()
        // console.log(this.twitch)
        const sx = this.$firebase.firestore()
        sx.collection('License')
          .doc(this.code)
          .get()
          .then(snap => {
            if (snap.data().Used === false) {
              sx.collection('License')
                .doc(this.code)
                .update({
                  Used: true,
                  user: userData.id,
                  twitchname: this.twitch.toLowerCase(),
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                  sx.collection('User')
                    .doc(userData.id)
                    .update({
                      License: {
                        DaysLeft: snap.data().Duration,
                        TotalDays: snap.data().Duration,
                        licensekey: this.code,
                        licensebeginning: firebase.firestore.FieldValue.serverTimestamp(),
                        LastStart: new Date(),
                      },
                      Package: snap.data().Package,
                      viewer: snap.data().Viewer,
                      twitchname: this.twitch.toLowerCase(),
                      follower: [0, snap.data().Follower],
                      Botpanel: {
                        Views: 5,
                        Active: false,
                        Viewlist: true,
                        CategoryChatbot: true,
                        Chatbot: true,
                        Viewbot: true,
                        chatinterval: [30, 300],
                        Loop: true,
                      },
                    })
                    .then(() => {
                      sx.collection('User')
                        .doc(userData.id)
                        .get()
                        .then(snap2 => {
                          // console.log(snap.data().Package)
                          // const expiresIn = 7200
                          // const createdAt = admin.firestore.Timestamp.now().toDate()
                          // createdAt.setSeconds(createdAt.getSeconds() + expiresIn);
                          // admin.firestore.Timestamp.fromDate(createdAt)
                          if (snap2.data().Package === 'None') {
                            this.show = true
                          } else {
                            this.valueslider = snap2.data().Botpanel.Views
                            this.max = snap2.data().viewer
                            this.active = snap2.data().Botpanel.Active
                            this.showstart = !snap2.data().Botpanel.Active
                            this.showstop = snap2.data().Botpanel.Active
                            this.chatbot = snap2.data().Botpanel.Chatbot
                            this.viewbot = snap2.data().Botpanel.Viewbot
                            this.timeslider = snap2.data().Botpanel.chatinterval
                            if (snap2.data().Botpanel.chatinterval === snap2.data().Botpanel.chatintervalxx) {
                              this.timeslider = [30, 180]
                            }
                            // console.log(snap.data().Botpanel.chatinterval)
                          }
                        })
                        .then(() => {
                          this.show = false
                          this.showstart = true
                          this.$swal({
                            title: 'Succeed!',
                            text: ` You just redeemed your ${snap.data().Package}Package!`,
                            icon: 'success',
                            customClass: {
                              confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                          })
                          this.$router.go()
                        })
                    })
                    .catch(error => {
                      // console.log(error)
                      this.$swal({
                        title: 'Errors!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
            } else {
              this.$swal({
                title: 'Error!',
                text: ' This License was already redeemed, if you believe that this is an error, please contact our Support via Discord or Mail!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(error => {
            console.error('Error adding document: ', error)
            this.$swal({
              title: 'Error!',
              text: ' This License does not exist, if you believe that this is an error, please contact our Support via Discord or Mail!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      } else {
        this.$swal({
          title: 'Error!',
          text: ' Please enter a twitchname!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

      // this.$swal({
      //   // icon: 'success',
      //   icon: 'AlertCircleIcon',
      //   title: 'This License does not exist, if you believe that this is a Failure, please contact our Support via Discord or Mail!',
      //   showConfirmButton: false,
      //   timer: 1500,
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //   },
      //   buttonsStyling: false,
      // })
    },
    // top end
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
