<template>
  <div>
    <b-card title="Latest Follows">
      <b-table
        small
        :fields="fields"
        :items="items"
        responsive="sm"
      >
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(Date)="data">
          {{ data.value.first+' - '+ data.value.last }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(progress)="data">
          <b-progress
            :value="data.value.value"
            max="100"
            :variant="data.value.variant"
            striped
          />
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="data.value.variant"
          >
            {{ data.value.status }}
          </b-badge>
        </template>

        <!-- A virtual composite column -->
        <!--        <template #cell(price)="data">-->
        <!--          {{ '$'+data.value }}-->
        <!--        </template>-->

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </b-card>

  </div>
</template>

<script>
import {
  BTable, BProgress, BBadge, BCardText, BCard,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'LastFollows',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BCard,
    BTable,
    BProgress,
    BBadge,
  },
  data() {
    return {
      reversed: false,
      fields: [
        // A virtual column that doesn't exist in items
        'index',
        // A column that needs custom formatting
        { key: 'date', label: 'Date' },
        { key: 'amount', label: 'Amount' },
        // A regular column
        { key: 'progress', label: 'Progress' },
        // A regular column
        { key: 'status', label: 'Status' },
        // A virtual column made up from two fields
      ],
      items: [
      ],
    }
  },
  mounted() {
    this.refreshLatestFollows()
  },
  methods: {
    refreshLatestFollowsOld() {
      const sx = this.$firebase.firestore()
      const userData = getUserData()
      sx.collection('User')
        .doc(userData.id).collection('Follower').doc('follow')
        .onSnapshot(snap => {
          this.items = []
          // const maximum = 10
          snap.data()
            .queries.forEach(x => {
            // console.log('hereComesTheX')
            // console.log(x)
            // console.log(this.items)
              const progressBar = (1 - (x.toFollow / x.totalFollow)) * 100
              let progBarVariant = 'danger'
              let statusInfo = 'danger'

              if (progressBar < 100) {
                progBarVariant = 'warning'
                statusInfo = 'In delivery'
              } else {
                progBarVariant = 'success'
                statusInfo = 'Delivered'
              }
              this.items.push({
                date: formatDate(x.startDate.toDate(), { dateStyle: 'full', timeStyle: 'short' }),
                amount: x.totalFollow,
                status: { status: statusInfo, variant: progBarVariant },
                progress: { value: progressBar, variant: progBarVariant },
              })
            })
          this.items.reverse()
          if (this.items.length > 10) this.items.splice(10, this.items.length - 1)
        })
    },
    refreshLatestFollows() {
      const sx = this.$firebase.firestore()
      const userData = getUserData()
      sx.collection('User')
        .doc(userData.id).collection('Follower')
        .orderBy('startDate', 'desc')
        .limit(10)
        .onSnapshot(async result => {
          this.items = []
          result.forEach(doc => {
            const task = doc.data()
            const progressBar = (1 - (task.toFollow / task.totalFollow)) * 100
            let progBarVariant = 'danger'
            let statusInfo = 'danger'

            if (progressBar < 100) {
              progBarVariant = 'warning'
              statusInfo = 'In delivery'
            } else {
              progBarVariant = 'success'
              statusInfo = 'Delivered'
            }
            this.items.push({
              date: formatDate(task.startDate.toDate(), { dateStyle: 'full', timeStyle: 'short' }),
              amount: task.totalFollow,
              status: { status: statusInfo, variant: progBarVariant },
              progress: { value: progressBar, variant: progBarVariant },
            })
          })
        })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date)
    },
  },
}
</script>
<style scoped>

</style>
